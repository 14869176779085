import React from 'react';

const NotFound = ()  => {
	return (
		<div className="text-center text-white mt-12">
			<h1 className="text-9xl">404</h1>
			<h2>Page not found</h2>
		</div>
	);
};

export default NotFound;
